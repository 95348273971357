<template>
  <div>
    <div>
      <div class="max-w-7xl mx-auto pt-24 px-4 sm:px-6 lg:px-8">
        <div class="sm:flex sm:flex-col sm:align-center">
          <h1 class="text-5xl font-extrabold text-gray-900 sm:text-center">Continuation courses</h1>
          <p class="mt-5 text-xl text-gray-500 sm:text-center">Buy some course to listen it.</p>
          <!--          <div class="relative mt-6 bg-gray-100 rounded-lg p-0.5 flex self-center sm:mt-8">-->
          <!--            <button type="button" class="relative bg-white border-gray-200 rounded-md shadow-sm py-2 w-1/2 text-sm font-medium text-gray-900 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-blue-500 focus:z-10 sm:w-auto sm:px-8">Monthly billing</button>-->
          <!--            <button type="button" class="ml-0.5 relative border border-transparent rounded-md py-2 w-1/2 text-sm font-medium text-gray-700 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-blue-500 focus:z-10 sm:w-auto sm:px-8">Yearly billing</button>-->
          <!--          </div>-->
        </div>
        <div class="mt-12 space-y-4 sm:mt-16 sm:space-y-0 grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-4">
          <router-link :to="`/course/${course.pk}`" v-for="course in courses" :key="course.name" class="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200 bg-gradient-to-b from-white to-blue-100 cursor-pointer hover:to-blue-200">
            <div class="p-6">
              <img v-if="course.image" :src="course.image" class="rounded-xl" style="aspect-ratio: 3/2">
              <h2 class="text-lg leading-6 font-medium text-gray-900">{{ course.product.name }}</h2>
<!--              <p class="mt-4 text-sm text-gray-500">{{ course.description }}</p>-->
              <p class="mt-8">
                <span class="text-4xl font-extrabold text-gray-900">{{ course['product'].prices.find(e=>e.active)?.unit_amount / 100 }} EUR</span>
                <!--                {{ ' ' }}-->
                <!--                <span class="text-base font-medium text-gray-500">/mo</span>-->
              </p>
              <button
                  v-if="user && !userCoursesId.includes(course.pk)"
                  class="mt-8 block w-full bg-blue-600 border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-blue-700"
                  @click.prevent="cartItemToggle(course)"
              >
                {{isInCart(course) ? 'Remove from cart' : 'Add to cart'}}
              </button>
              <div v-else>
                <CheckIcon class="flex-shrink-0 h-10 w-10 text-green-500 mt-4" aria-hidden="true" />
                <button
                    @click.prevent="$router.push('/course/start/' + course.pk)"
                    class="mt-8 block w-full bg-green-600 border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-green-800"
                >
                  Start
                </button>
              </div>
              <button class="mt-8 block w-full bg-white border border-transparent rounded-md py-2 text-sm font-semibold text-blue-600 text-center hover:bg-gray-100">See details</button>
            </div>
            <div class="pt-6 pb-8 px-6">
              <h3 class="text-xs font-medium text-gray-900 tracking-wide uppercase">Additional info</h3>
              <div role="list" class="mt-6 space-y-4">
                <div class="flex space-x-3">
                  <span class="text-sm text-gray-500">{{ course.additional_info }}</span>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import {CheckIcon} from '@heroicons/vue/solid'

export default {
  name: 'Home',
  components: {
    CheckIcon
  },
  data() {
    return {
    }
  },
  methods: {
    ...mapActions('courses', ['fetchCourses']),
    ...mapMutations('cart', ['cartItemToggle']),
    isInCart(course) {
      return this.cart.findIndex(e => e.pk == course.pk) != -1
    }
  },
  computed: {
    ...mapGetters('courses', ['courses']),
    ...mapGetters('cart', ['cart']),
    ...mapGetters('auth', ['userCoursesId', 'user', 'userCourses'])
  },
  async mounted() {
    await this.fetchCourses('continuation')
  }
}
</script>
